@import 'src/client_customizations/scss/client_master.scss';

.ComparedCarDetails {
       font-family: 'Noble';
  margin: 0;
  text-align: center;
  .btn {
    margin-top: 1.5rem;
  }
  .title {
    color: #767676;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }

  .value {
    color: #000000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin:10px 0 0;
  }

  .afterIncentives {
       .value {
              font-size:32px;
       }
  }


  p {
    margin-bottom: 0;
  }
  .afterIncentives {
    .DetailColumn {
      border-top: 1px solid #dbdbdb;
    }
  }
  .row {
       display: flex;
       justify-content: center;

       &.last {
              .DetailColumn {
                     border-bottom: none;
                     margin-bottom: 50px;
              }
       }

    .DetailColumn {
       width:100%;
       top: 0.4rem;
       padding: 0.5rem 0 1.12rem 0;
       border-bottom: 1px solid #dbdbdb;
       text-align: center;
    }
  }

       .h5 {
              font-size: 24px;
              font-weight: 600;
              color: $gray;
       }
}
