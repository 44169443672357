.filter-container {
  width: 30%;
}

#incentives {
       .container {
              align-items: flex-start;
       }
}

.incentives-container {
  width: 80%;

  @media(max-width:768px) {
       width:100%;
  }

  h2 {
       font-family: 'Noble-Light';
       margin: 0 0 40px;
       font-weight: 600;
       font-size:  16px;
       color: #000000;
       line-height: 1.3em;
       text-align: center;

       strong {
              font-family: 'Noble';
       }
  }
}

.evHead {
  font-family: Futura PT;
  font-size: 46px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.lead {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;

  max-width: 931px;
  margin: 0 auto 50px;
}