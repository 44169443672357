@import 'src/client_customizations/scss/client_master.scss';

.IncentiveCard {
       position: relative;
       text-decoration: none;
       margin-right: 32px;
       margin-bottom: 32px;
       border: 1px solid #ccc;
       border-top: 4px solid #9C8654;
       background-color: white;
       width: 225px;
       height: auto;
       display: flex;
       flex-direction: column;
       padding:24px 15px 50px 15px;

       @media(max-width:768px) {
              width:100%;
              margin:0 0 20px;
       }
}

.IncentiveCardTop {
       padding:0 0 3em;
       text-align: center;
       font-size: 14px;
       color: $IncentiveCard-card-body-color;
       letter-spacing: 0.75px;
       text-transform: uppercase;
       border-radius: 0;
       font-weight: 400;

       .card-title {
              margin-top: 1rem;
              color: $IncentiveCard-card-title-color;
       }

       span {
              font-family: 'Noble-Book';
              font-style: normal;
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: center;
              color: #000000;
       }
}

.IncentiveCardBody {
       padding: 0rem 0.5rem 0.5rem 0.5rem;
       text-align: center;
       color: $IncentiveCard-card-body-color;
       border-radius: 0;
       margin:0 0 50px 0;

       .card-title {
              height: 55px;
              font-family: 'Noble';
              font-size: 24px;
              font-weight: 400;
              line-height: 1em;
              letter-spacing: 0em;
              text-align: center;
              margin-top: 16px;
       }

       .light {
              font-family: 'Noble-Book';
              font-size: 18px;
              font-weight: 400;
              line-height: 1.3em;
              letter-spacing: 0.25px;
              text-align: center;
              max-width: 80%;
              margin: 24px auto 0;
       }

       img {
              margin-top: -12px;
              margin-bottom: -16px;
              width: 60px;
       }

}
 .IncentiveCardDetails {
        margin-top: 20px;

        p.h3 {
               font-family: 'Noble-Book';
               font-weight: 390;
               text-decoration: none;
               font-size: 14px;
               margin: 0;
               color: #000;
               line-height: 1.3em;

               &:hover, &:active {
                      text-decoration: none !important;
               }
        }
 }
.IncentiveCardBottom {
       width: 100%;
       color: #000000;
       font-size: 14px;
       font-weight: 400;
       height: 24px;
       padding:0;
       position: absolute;
       bottom:25px;
       left:0;
       box-sizing: border-box;

       @media (max-width: 992px) {
              padding: 20px 0 0;
       }

       &.active {
              position: absolute;
       }

       .IncentiveCardButtonLinks {
              display: flex;
              // flex-direction: column;x
              padding:0 5px;

              

              a, button {
                     padding:0;
                     width:50%;
                     font-family: 'Noble-Bold';
                     font-size: 14px;
                     font-weight: 700;
                     line-height: 1em;
                     letter-spacing: 0px;
                     text-align: center;
                     color: #000;
                     text-decoration: none;

                     @media(max-width:450px) {
                            width:100%;
                     }
              }

              a {
                     border-right:1px solid #D8D8D8;
                     color:#000;

                     @media(max-width:450px) {
                            border-right: none;
                            margin:0 0 5px;
                     }

              }

              button.showBtn {
                     cursor: pointer;
                     text-align: center;
                     border: none;
                     background-color: transparent;

              }
       }

      
}

.displayNone {
       display: none !important;
}

.showAllCard {
       min-height: 345px !important;
       height: auto !important;
       max-height: 1000px !important;

       .description {
              min-height: 88px !important;
              height: auto !important;
              max-height: 1000px !important;
       }
}

@media (min-width: 768px) {
       .IncentiveCardBody {
              padding: 0rem 0.5rem 0.5rem 0.5rem;
       }
}