

@font-face {
       font-family: 'Noble-Light';
       font-style: normal;
       font-weight: 200;
       src: local('Noble Light'),
              url('../src/client_customizations/assets/fonts/fonnts.com-Nobel_Light.otf') format('opentype');
}

@font-face {
       font-family: 'Noble-Book';
       font-style: normal;
       font-weight: 400;
       src: local('Noble Book'),
               url('../src/client_customizations/assets/fonts/fonnts.com-Nobel_Book.otf') format('opentype');
}

@font-face {
       font-family: 'Noble';
       font-style: normal;
       font-weight: 500;
       src: local('Noble'),
              url('../src/client_customizations/assets/fonts/fonnts.com-Nobel_Regular.otf') format('opentype');
}

@font-face {
       font-family: 'Noble-Bold';
       font-style: normal;
       font-weight: 700;
       src: local('Noble Bold'),
              url('../src/client_customizations/assets/fonts/fonnts.com-Nobel_Bold.otf') format('opentype');
}




body, html {
    margin: 0;
    padding: 0;
}

.mobile-only {
       display:none;

       @media(max-width:768px) {
              display:block;
       }
}

.desktop-only {
       @media(max-width:768px) {
              display:none;
       }
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw; 
    height: auto; 
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.tooltipMain > .tooltip-inner {
       font-family: 'Noble';
	padding: 10px !important;
	text-align: center !important;
       color:#fff;
       font-size:13px;
       font-weight: 500;
}
.tooltipBody {
       border-radius: 5px;
	background-color: #1E2123 !important;
}
.tooltipArrow {
	&::before {
		border-right-color: rgba(97, 97, 97, 0.9) !important;
	}
}

.title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:50px 0 0;

    h1 {
       font-family: 'Noble-Book';
       margin:0 0 40px;
       font-size: 24px;
       font-weight: 390;

       text-align: center;

       @media(max-width:768px) {
              padding:0 15px;
              font-size:26px;
       }
    }

    p {
        font-size: 48px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.5px;
        text-align: center;
    }
}

.tabs-container {
       width:100%;
       margin:0 0 60px 0;
       display: flex;
       justify-content: center;

       .tabs {
              button {
                     font-family: 'Noble';
                     background-color: transparent;
                     border-top:none;
                     border-right:none;
                     border-left:none;
                     padding:8px 24px;
                     font-size: 13px;
                     &:focus {
                            outline: none;
                     }
                   

                     @media(max-width:560px) {
                            padding:8px 15px;
                            font-size:14px;
                     }
              }
       }
}

.container {
       align-items: flex-start;

       @media(max-width:768px) {
              flex-wrap: wrap;
       }
}

.container {
       max-width:1200px;
       margin:0 auto;
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
       box-sizing: border-box; 

}

.container-fluid {
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
}

.content-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.filters-container {
       width:350px;
       margin-right:50px;

       @media(max-width:768px) {
              width:100%;
              margin-right:0;
              margin-bottom:50px;
       }

       h2 {
              font-family: 'Noble-Bold';
              margin:0 0 20px;
              font-weight: 700;
              font-size: 16px;
              color:#000000;
              line-height: 1em;
       }

       hr {
              border:1px solid #D8D8D8;
              margin:30px 0 25px;
       }

       .input-group-container {
              margin-bottom:25px;

              .input-group-container label {
                     display:none !important;
              }
       }

       .incentive-label-select, .input-group-container label, .incentive-label-select label {
              display: flex;
              margin:0 0 10px;
              font-family: 'Noble';
              color:#000000;
              height: 40px;

              &:empty {
                     display:none;
              }

              span {
                     position:relative;
                     margin-left:5px;
                     height:20px;
              }
       }

       .zip-code-input-group {
              position:relative;

              .input-group-prepend {
                     position:absolute;
                     top: 50%;
                     transform: translateY(-50%);
                     left:10px;
                     .input-group-text {
                                   background-color: white;
                                   border: none;
                            }
              }

              input.form-control {
                     font-family: 'Noble';
                     width:100%;
                     padding: 16px 45px 16px 50px;
                     border: 1px solid #000000;
                     background: #fff;
                     font-size: 16px;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
                     height:48px !important;
                     border-radius: 0px;
                     background-repeat: no-repeat;
                     background-position: 92% 10px;
                     background-image: url("../src/client_customizations/assets/images/icons/zipdode_bar.svg");
              }

       }

       .Range.form-group {
              margin:-10px 0 0;

              input[type="range"] {
                     position: relative;
                     width: 100%;
                     height: 25px;
                     outline: none;
                     background:transparent;
                     border:none;

                     &:before {
                            position: relative;
                            top:7px;
                            content:"";
                            display:block;
                            width:3px;
                            height:12px;
                            background-color:#D8D8D8;
                     }

                     &:after {
                            position: relative;
                            top:7px;
                            content:"";
                            display:block;
                            width:3px;
                            height:12px;
                            background-color:#D8D8D8
                            ;
                     }
              }
       }

       .form-group.currency-wrap {
              position: relative;

              input[type="number"] {
                     position: relative;
                     font-family: 'Noble';
                     position: relative;
                     width: 100%;
                     height: 25px;
                     font-size:14px;
                     border-radius: 8px;
                     border: 1px solid #D8D8D8;
                     padding: 25px 45px 25px 20px;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
                     font-size:14px;
                     z-index: 4;
              }

              &:before {
                     position: absolute;
                     content: "$";
                     top:14px;
                     left: 10px;
                     font-family: 'Noble';
                     color:#000000;
                     z-index: 5;
              }

              &:after {
                     position: absolute;
                     content: " / kWh";
                     top: 14px;
                     left: 50px;
                     font-family: 'Noble';
                     color:#000000;
                     z-index: 5;
              }

              .currency-disclaimer {
                     p {
                            font-family: 'Noble-Light';
                            font-size:12px;
                     }
              }
       }

       .text-danger.small {
              font-family: 'Noble';
              font-size:12px;
              line-height: 1em;
       }

       select {
              color:#000000;
              cursor: pointer;
              font-family: 'Noble';
              position: relative;
              width:100%;
              border: 1px solid #000000;
              border-radius: 0px;
              background: #fff;
              font-size: 16px;
              appearance: none;
              height: 48px !important;
              background-repeat: no-repeat;
              background-position: 98% 0px;
              background-image: url("../src/client_customizations/assets/images/icons/arrow_bordered.svg");
       }


       select::-ms-expand {
              display: none;
       }

       .incentives-submit-button {
              font-family: 'Noble';
              font-size:16px;
              font-weight: 600;
              line-height: 1em;
              width:100%;
              padding:15px 0 17px;
              background-color: #000000;
              color:#ffffff;
              border:none;
              border-radius: 25px;
              cursor: pointer;
       }

       .incentives-disclaimer {
              font-size:12px;
              font-weight: 400;
              font-family: 'Noble-Light';
              margin-top:25px;
              line-height: 1.2em;
              color:#000000;
       }
}

.disclaimer {
       margin-top: 16px;
       font-family: Roboto;
       font-size: 12px;
       font-weight: 400;
       line-height: 16px;
       letter-spacing: 0px;
       text-align: left;
}

.footer-container {
       width:100%;
       margin:100px 0 0;
       padding:0 0 50px;

       .footer-content {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;

              img {
                     max-width:100%;
              }

              p {
                     font-family: 'Noble-Light';
                     font-size:12px;
                     text-align: center;
              }
       }
}

.btn-lexus {
       background-color: #ffffff;
       color: #000000;
       font-weight: 700;
       font-size: 13px;
       font-family: 'Noble-Bold';
       width: 100%;
       border: 2px solid #000000;
       height: 48px;
       cursor: pointer;

       &:focus {
              outline: none;
       }

       &:hover {
              color: #ffffff;
              background-color: #000000;
       }

}


.input-group-container {
       margin: 20px 0px;
       .form-control {
              color: #000000;
                     cursor: pointer;
                     font-family: 'Noble';
                     position: relative;
                     width: 100%;
                     border: 1px solid #000000;
                     border-radius: 0px;
                     background: #fff;
                     font-size: 16px;
                     appearance: none;
                     height: 48px !important;
                     background-repeat: no-repeat;
                     background-position: 98% 0px;
                     background-image: url("../src/client_customizations/assets/images/icons/arrow_bordered.svg");
       }
}
#zipcodefield {
       &.form-control {
                     font-family: 'Noble';
                     width: 100%;
                     border: 1px solid #000000;
                     background: #fff;
                     font-size: 16px;
                     -webkit-box-sizing: border-box;
                     -moz-box-sizing: border-box;
                     box-sizing: border-box;
                     height: 48px !important;
                     border-radius: 0px;
                     background-repeat: no-repeat;
                     background-position: 92% 10px;
                     background-image: url("../src/client_customizations/assets/images/icons/zipdode_bar.svg");
              }
}
       
.modal-body {
       .incentives-disclaimer {
                     font-size: 11px;
                     font-weight: 400;
                     font-family: 'Noble-Light';
                     margin-top: 25px;
                     line-height: 1.2em;
                     color: #000000;
              }
       h2 {
              font-family: 'Noble';
              font-size: 16px;
              font-weight: 700;
       }
       label {
              font-size: 14px;
       }
}
.modal-title {
       .btn {
              background-color: #ffffff;
              color: #000000;
              border: none;
              display: flex;
              height: 23px;
              justify-content: flex-end;
       }
}
.modal-content {
       .modal-header {
              display: flex;
              justify-content: flex-end;
              border: none;
       }
}
.modal-footer {
       border-top: none;
       .btn {
              font-family: 'Noble';
              border-radius: 0px;
              font-weight: 700;
              font-size: 13px;
       }
}