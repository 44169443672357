.emissions-container {
       width:70%;    

       @media(max-width:768px) {
              width:100%;
       }

       h2 {
              font-family: 'Noble';
              margin: 0;
              font-weight: 400;
              font-size: 16px;
              color: #000000;
              line-height: 1.3em;
              text-align: center;
              text-transform: uppercase;
              margin-bottom: 40px;

              strong {
                     font-family: 'Noble-Bold';
              }
       }

       h3 {
              font-family: 'Noble-Book';
              text-align: center;
              font-size: 14px;
              font-weight: 400;

              strong {
                     font-family: 'Noble-Bold';
              }
       }

       .emissions-bar-graph-container {
              width:60%;
              min-width:550px;
              margin: 50px auto 0;

              @media(max-width:768px) {
                     width:100%;
                     min-width:0;
              }

              .emissions-bar-graph {
                     height:400px;

                     canvas {
                            width:100% !important;
                     }

                     p {
                            font-family: 'Noble-Book';
                            text-align: center;
                            font-size:14px;
                            font-weight: 400;

                            a {
                                   font-family: "Noble-Bold";
                                   color:#000000;
                                   text-transform: uppercase;
                                   text-decoration: underline;
                                   text-decoration-color: #9C8654;
                                   font-weight: 700;

                            }
                     }
              }
       }
}