body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/*
Naming Schema :
Component Outer Class
inner class Kebab Case
all lowercase with - separating words.
//$CalcPanels-nav-button-background-color_active:  #000000;

Include  this any componets scss file
@import "src/client_customizations/scss/client_master.scss";

*/
/* Fonts */
/*
    Header
*/
/*
    Main
*/
/*
Page Layout
*/
/*
    Generic Footer
*/
/*
    CalcPanels
*/
/*
    EVCard
*/
/*
    PricePanels
*/
/*
    Incentive Card
*/
/*
    Incentive Preferences
*/
/*
    Link Card
*/
/*
    Charging Station
*/
/*
    Dealer Location Card
*/
/*
    ChargingMap
*/
/*
    Render Item
*/
/*
    Button Group
*/
/*
    Input Range
*/
/*
    Event Card
*/
/*
    Event Filter Controls
*/
/*
    Pages: Homepage
*/
/*
    Pages: EVs
*/
/* Pages Compare Vehicles */
/* Pages Compare Vehicles */
/* Pages: Inventory */
/*
    Pages: Events
*/
/* Maps */
.google-map-cluster-marker {
  width: 40px;
  height: 52px;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 80.555 100' enable-background='new 0 0 80.555 100' xml:space='preserve'><path d='M76.389,26.389V18.75c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-8.333V18.75  c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-4.167v4.167c0,5.947,4.157,10.915,9.723,12.18v15.598  l4.156,30.662c-0.059,3.014-2.518,5.449-5.545,5.449c-3.062,0-5.553-2.489-5.556-5.55h0.001V50.005L58.333,50  c0-4.595-3.738-8.333-8.333-8.333h-8.334V8.102C41.667,3.634,37.929,0,33.333,0h-25C3.738,0,0,3.634,0,8.102V100h41.667V47.222H50  c1.531,0,2.777,1.247,2.777,2.778l0.001,0.005v38.883h-0.001c0,6.127,4.984,11.111,11.111,11.111C70.015,100,75,95.016,75,88.889  l-4.167-30.744V42.735c5.565-1.265,9.722-6.232,9.722-12.18v-4.167H76.389z M10.524,73.299l5.983-11.965H9.028l10.47-17.95h7.478  L19.498,55.35h8.974L10.524,73.299z M36.111,29.398c0,1.404-1.247,2.546-2.778,2.546h-25c-1.531,0-2.778-1.142-2.778-2.546V8.102  c0-1.404,1.247-2.546,2.778-2.546h25c1.531,0,2.778,1.142,2.778,2.546V29.398z'/></svg>");
  border-radius: 10%;
  padding: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
  -ms-flex-align: top;
      align-items: top; }
  .google-map-cluster-marker .text {
    background-color: #fff;
    height: 12px; }

.google-map-location-marker-button {
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627; }
  .google-map-location-marker-button :hover {
    background-color: transparent; }
  .google-map-location-marker-button :active {
    position: relative;
    top: 1px; }
  .google-map-location-marker-button :hover {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    z-index: 9999; }
  .google-map-location-marker-button :focus {
    outline: none; }

.IncentiveCard {
  position: relative;
  text-decoration: none;
  margin-right: 32px;
  margin-bottom: 32px;
  border: 1px solid #ccc;
  border-top: 4px solid #9C8654;
  background-color: white;
  width: 225px;
  height: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 24px 15px 50px 15px; }
  @media (max-width: 768px) {
    .IncentiveCard {
      width: 100%;
      margin: 0 0 20px; } }

.IncentiveCardTop {
  padding: 0 0 3em;
  text-align: center;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: 400; }
  .IncentiveCardTop .card-title {
    margin-top: 1rem;
    color: #000; }
  .IncentiveCardTop span {
    font-family: 'Noble-Book';
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #000000; }

.IncentiveCardBody {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  color: #333333;
  border-radius: 0;
  margin: 0 0 50px 0; }
  .IncentiveCardBody .card-title {
    height: 55px;
    font-family: 'Noble';
    font-size: 24px;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 16px; }
  .IncentiveCardBody .light {
    font-family: 'Noble-Book';
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3em;
    letter-spacing: 0.25px;
    text-align: center;
    max-width: 80%;
    margin: 24px auto 0; }
  .IncentiveCardBody img {
    margin-top: -12px;
    margin-bottom: -16px;
    width: 60px; }

.IncentiveCardDetails {
  margin-top: 20px; }
  .IncentiveCardDetails p.h3 {
    font-family: 'Noble-Book';
    font-weight: 390;
    text-decoration: none;
    font-size: 14px;
    margin: 0;
    color: #000;
    line-height: 1.3em; }
    .IncentiveCardDetails p.h3:hover, .IncentiveCardDetails p.h3:active {
      text-decoration: none !important; }

.IncentiveCardBottom {
  width: 100%;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  height: 24px;
  padding: 0;
  position: absolute;
  bottom: 25px;
  left: 0;
  box-sizing: border-box; }
  @media (max-width: 992px) {
    .IncentiveCardBottom {
      padding: 20px 0 0; } }
  .IncentiveCardBottom.active {
    position: absolute; }
  .IncentiveCardBottom .IncentiveCardButtonLinks {
    display: -ms-flexbox;
    display: flex;
    padding: 0 5px; }
    .IncentiveCardBottom .IncentiveCardButtonLinks a, .IncentiveCardBottom .IncentiveCardButtonLinks button {
      padding: 0;
      width: 50%;
      font-family: 'Noble-Bold';
      font-size: 14px;
      font-weight: 700;
      line-height: 1em;
      letter-spacing: 0px;
      text-align: center;
      color: #000;
      text-decoration: none; }
      @media (max-width: 450px) {
        .IncentiveCardBottom .IncentiveCardButtonLinks a, .IncentiveCardBottom .IncentiveCardButtonLinks button {
          width: 100%; } }
    .IncentiveCardBottom .IncentiveCardButtonLinks a {
      border-right: 1px solid #D8D8D8;
      color: #000; }
      @media (max-width: 450px) {
        .IncentiveCardBottom .IncentiveCardButtonLinks a {
          border-right: none;
          margin: 0 0 5px; } }
    .IncentiveCardBottom .IncentiveCardButtonLinks button.showBtn {
      cursor: pointer;
      text-align: center;
      border: none;
      background-color: transparent; }

.displayNone {
  display: none !important; }

.showAllCard {
  min-height: 345px !important;
  height: auto !important;
  max-height: 1000px !important; }
  .showAllCard .description {
    min-height: 88px !important;
    height: auto !important;
    max-height: 1000px !important; }

@media (min-width: 768px) {
  .IncentiveCardBody {
    padding: 0rem 0.5rem 0.5rem 0.5rem; } }

.homepage-incentives-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center; }

/*
Naming Schema :
Component Outer Class
inner class Kebab Case
all lowercase with - separating words.
//$CalcPanels-nav-button-background-color_active:  #000000;

Include  this any componets scss file
@import "src/client_customizations/scss/client_master.scss";

*/
/* Fonts */
/*
    Header
*/
/*
    Main
*/
/*
Page Layout
*/
/*
    Generic Footer
*/
/*
    CalcPanels
*/
/*
    EVCard
*/
/*
    PricePanels
*/
/*
    Incentive Card
*/
/*
    Incentive Preferences
*/
/*
    Link Card
*/
/*
    Charging Station
*/
/*
    Dealer Location Card
*/
/*
    ChargingMap
*/
/*
    Render Item
*/
/*
    Button Group
*/
/*
    Input Range
*/
/*
    Event Card
*/
/*
    Event Filter Controls
*/
/*
    Pages: Homepage
*/
/*
    Pages: EVs
*/
/* Pages Compare Vehicles */
/* Pages Compare Vehicles */
/* Pages: Inventory */
/*
    Pages: Events
*/
/* Maps */
.google-map-cluster-marker {
  width: 40px;
  height: 52px;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 80.555 100' enable-background='new 0 0 80.555 100' xml:space='preserve'><path d='M76.389,26.389V18.75c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-8.333V18.75  c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-4.167v4.167c0,5.947,4.157,10.915,9.723,12.18v15.598  l4.156,30.662c-0.059,3.014-2.518,5.449-5.545,5.449c-3.062,0-5.553-2.489-5.556-5.55h0.001V50.005L58.333,50  c0-4.595-3.738-8.333-8.333-8.333h-8.334V8.102C41.667,3.634,37.929,0,33.333,0h-25C3.738,0,0,3.634,0,8.102V100h41.667V47.222H50  c1.531,0,2.777,1.247,2.777,2.778l0.001,0.005v38.883h-0.001c0,6.127,4.984,11.111,11.111,11.111C70.015,100,75,95.016,75,88.889  l-4.167-30.744V42.735c5.565-1.265,9.722-6.232,9.722-12.18v-4.167H76.389z M10.524,73.299l5.983-11.965H9.028l10.47-17.95h7.478  L19.498,55.35h8.974L10.524,73.299z M36.111,29.398c0,1.404-1.247,2.546-2.778,2.546h-25c-1.531,0-2.778-1.142-2.778-2.546V8.102  c0-1.404,1.247-2.546,2.778-2.546h25c1.531,0,2.778,1.142,2.778,2.546V29.398z'/></svg>");
  border-radius: 10%;
  padding: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
  -ms-flex-align: top;
      align-items: top; }
  .google-map-cluster-marker .text {
    background-color: #fff;
    height: 12px; }

.google-map-location-marker-button {
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627; }
  .google-map-location-marker-button :hover {
    background-color: transparent; }
  .google-map-location-marker-button :active {
    position: relative;
    top: 1px; }
  .google-map-location-marker-button :hover {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    z-index: 9999; }
  .google-map-location-marker-button :focus {
    outline: none; }

input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;
  /*fix for FF unable to apply focus style bug */
  border: 1px solid white;
  margin-top: 5px;
  border-radius: 5px; }

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background: #e5e5e5;
  border: none;
  border-radius: 0px; }

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #000000;
  margin-top: -7px;
  cursor: pointer; }

input[type="range"]:focus {
  outline: none; }

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc; }

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  background: #e5e5e5;
  border: none;
  border-radius: 3px; }

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #000000;
  cursor: pointer; }

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px; }

input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent; }

input[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px; }

input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px; }

input[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #005f83; }

input[type="range"]:focus::-ms-fill-lower {
  background: #888; }

input[type="range"]:focus::-ms-fill-upper {
  background: #ccc; }

.Range .description {
  font-family: 'Noble';
  margin-top: 0px;
  text-align: center;
  color: #2A2A2A;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center; }

.MatchScoreOptions .h2 {
  font-family: "Roboto, Open Sans", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left; }

.filter-container {
  width: 30%; }

#incentives .container {
  -ms-flex-align: start;
      align-items: flex-start; }

.incentives-container {
  width: 80%; }
  @media (max-width: 768px) {
    .incentives-container {
      width: 100%; } }
  .incentives-container h2 {
    font-family: 'Noble-Light';
    margin: 0 0 40px;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 1.3em;
    text-align: center; }
    .incentives-container h2 strong {
      font-family: 'Noble'; }

.evHead {
  font-family: Futura PT;
  font-size: 46px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase; }

.lead {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 931px;
  margin: 0 auto 50px; }

.doughnutGraph-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 991px) {
    .doughnutGraph-container {
      -ms-flex-direction: column;
          flex-direction: column; } }
  .doughnutGraph-container .doughnutGraph {
    display: block;
    width: 500px;
    height: 500px; }
    @media (max-width: 991px) {
      .doughnutGraph-container .doughnutGraph {
        width: 100%;
        height: auto; } }
    .doughnutGraph-container .doughnutGraph canvas {
      width: 100% !important; }
  .doughnutGraph-container .doughnutGraph-legend-container {
    width: calc(100% - 500px);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
        align-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    @media (max-width: 991px) {
      .doughnutGraph-container .doughnutGraph-legend-container {
        width: 100%; } }
    .doughnutGraph-container .doughnutGraph-legend-container .doughnutGraph-legend {
      width: 100%;
      margin: 0 0 5px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
      .doughnutGraph-container .doughnutGraph-legend-container .doughnutGraph-legend span.indication-block {
        position: relative;
        top: 2px;
        width: 16px;
        height: 16px;
        display: block;
        margin: 0 10px 0 0; }
      .doughnutGraph-container .doughnutGraph-legend-container .doughnutGraph-legend span {
        font-family: 'Noble-Light';
        font-size: 16px;
        color: #000000;
        font-weight: 600; }

.cost-of-ownership-container {
  width: 70%;
  -ms-flex-item-align: start;
      align-self: flex-start; }
  @media (max-width: 768px) {
    .cost-of-ownership-container {
      width: 100%; } }
  .cost-of-ownership-container h2 {
    font-family: 'Noble-Light';
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    line-height: 1.3em;
    text-align: center; }
    .cost-of-ownership-container h2 strong {
      font-family: 'Noble'; }
  .cost-of-ownership-container h3 {
    font-family: 'Noble-Light';
    text-align: center;
    font-weight: 400; }
    .cost-of-ownership-container h3 strong {
      font-family: 'Noble'; }

.bubble-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center; }

.CostOfOwnership {
  padding-top: 50px; }

/*
Naming Schema :
Component Outer Class
inner class Kebab Case
all lowercase with - separating words.
//$CalcPanels-nav-button-background-color_active:  #000000;

Include  this any componets scss file
@import "src/client_customizations/scss/client_master.scss";

*/
/* Fonts */
/*
    Header
*/
/*
    Main
*/
/*
Page Layout
*/
/*
    Generic Footer
*/
/*
    CalcPanels
*/
/*
    EVCard
*/
/*
    PricePanels
*/
/*
    Incentive Card
*/
/*
    Incentive Preferences
*/
/*
    Link Card
*/
/*
    Charging Station
*/
/*
    Dealer Location Card
*/
/*
    ChargingMap
*/
/*
    Render Item
*/
/*
    Button Group
*/
/*
    Input Range
*/
/*
    Event Card
*/
/*
    Event Filter Controls
*/
/*
    Pages: Homepage
*/
/*
    Pages: EVs
*/
/* Pages Compare Vehicles */
/* Pages Compare Vehicles */
/* Pages: Inventory */
/*
    Pages: Events
*/
/* Maps */
.google-map-cluster-marker {
  width: 40px;
  height: 52px;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 80.555 100' enable-background='new 0 0 80.555 100' xml:space='preserve'><path d='M76.389,26.389V18.75c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-8.333V18.75  c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-4.167v4.167c0,5.947,4.157,10.915,9.723,12.18v15.598  l4.156,30.662c-0.059,3.014-2.518,5.449-5.545,5.449c-3.062,0-5.553-2.489-5.556-5.55h0.001V50.005L58.333,50  c0-4.595-3.738-8.333-8.333-8.333h-8.334V8.102C41.667,3.634,37.929,0,33.333,0h-25C3.738,0,0,3.634,0,8.102V100h41.667V47.222H50  c1.531,0,2.777,1.247,2.777,2.778l0.001,0.005v38.883h-0.001c0,6.127,4.984,11.111,11.111,11.111C70.015,100,75,95.016,75,88.889  l-4.167-30.744V42.735c5.565-1.265,9.722-6.232,9.722-12.18v-4.167H76.389z M10.524,73.299l5.983-11.965H9.028l10.47-17.95h7.478  L19.498,55.35h8.974L10.524,73.299z M36.111,29.398c0,1.404-1.247,2.546-2.778,2.546h-25c-1.531,0-2.778-1.142-2.778-2.546V8.102  c0-1.404,1.247-2.546,2.778-2.546h25c1.531,0,2.778,1.142,2.778,2.546V29.398z'/></svg>");
  border-radius: 10%;
  padding: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
  -ms-flex-align: top;
      align-items: top; }
  .google-map-cluster-marker .text {
    background-color: #fff;
    height: 12px; }

.google-map-location-marker-button {
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627; }
  .google-map-location-marker-button :hover {
    background-color: transparent; }
  .google-map-location-marker-button :active {
    position: relative;
    top: 1px; }
  .google-map-location-marker-button :hover {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    z-index: 9999; }
  .google-map-location-marker-button :focus {
    outline: none; }

#compare-vehicles {
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%; }
  #compare-vehicles .container {
    -ms-flex-direction: column;
        flex-direction: column; }
  #compare-vehicles .compare-vehicles-toggle {
    width: 100%;
    display: none; }
    @media (max-width: 768px) {
      #compare-vehicles .compare-vehicles-toggle {
        display: block; } }
    #compare-vehicles .compare-vehicles-toggle .ComparedVehicleToggle button.btn {
      font-family: 'Noble';
      width: 50%;
      background-color: #B00716;
      border: none;
      font-size: 20px;
      cursor: pointer; }
      #compare-vehicles .compare-vehicles-toggle .ComparedVehicleToggle button.btn.active {
        font-weight: bold;
        background-color: #9C8654; }
  #compare-vehicles .compare-vehicles-title {
    width: 100%; }
    #compare-vehicles .compare-vehicles-title h2 {
      font-family: 'Noble-Light';
      text-transform: none;
      text-align: center;
      margin: 0;
      font-size: 24px; }
    #compare-vehicles .compare-vehicles-title p.lead {
      font-family: 'Noble-Light';
      text-align: center;
      font-size: 16px; }
  #compare-vehicles .compared-section {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    grid-gap: 50px;
    gap: 50px; }
  #compare-vehicles .compared-vehicle {
    width: 50%;
    max-width: 300px; }
    @media (max-width: 768px) {
      #compare-vehicles .compared-vehicle {
        width: 100%;
        max-width: none; }
        #compare-vehicles .compared-vehicle.d-none {
          display: none; } }
    #compare-vehicles .compared-vehicle .ComparedCarDetails img {
      width: 100%; }
  #compare-vehicles .compared-graph-section {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    background: #F6F6F6;
    padding: 75px 0 0; }
    #compare-vehicles .compared-graph-section .container {
      width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-align: start;
          align-items: flex-start; }
    #compare-vehicles .compared-graph-section .graphs-container {
      width: 70%;
      margin: 0 auto; }
      @media (max-width: 768px) {
        #compare-vehicles .compared-graph-section .graphs-container {
          width: 100%; } }
      #compare-vehicles .compared-graph-section .graphs-container .chart-container {
        background-color: #ffffff;
        border: 1px solid #D8D8D8;
        padding: 40px;
        text-align: center;
        border-radius: 8px;
        margin: 0 0 50px; }
        #compare-vehicles .compared-graph-section .graphs-container .chart-container.nmb {
          margin-bottom: 0; }
        #compare-vehicles .compared-graph-section .graphs-container .chart-container .graph-sub-title {
          font-family: 'Noble';
          margin: 0 0 50px;
          font-size: 18px; }
        #compare-vehicles .compared-graph-section .graphs-container .chart-container .bubble-container .bubble {
          -ms-flex-pack: center;
              justify-content: center; }
          #compare-vehicles .compared-graph-section .graphs-container .chart-container .bubble-container .bubble.large img {
            height: 50px; }
          #compare-vehicles .compared-graph-section .graphs-container .chart-container .bubble-container .bubble.medium img {
            height: 30px; }
          #compare-vehicles .compared-graph-section .graphs-container .chart-container .bubble-container .bubble.small img {
            width: 20px;
            height: auto; }
          #compare-vehicles .compared-graph-section .graphs-container .chart-container .bubble-container .bubble p {
            font-family: 'Noble';
            margin: 0; }
        #compare-vehicles .compared-graph-section .graphs-container .chart-container .bubble-title {
          font-family: 'Noble-Light';
          margin: 20px 0 0;
          font-size: 16px; }
      #compare-vehicles .compared-graph-section .graphs-container .chart-disclaimer {
        font-family: 'Noble-Light';
        font-size: 12px;
        color: #000000;
        margin: 0 0 50px; }
  #compare-vehicles .input-group-container {
    margin-bottom: 25px; }
    #compare-vehicles .input-group-container label {
      display: -ms-flexbox;
      display: flex;
      margin: 0 0 10px;
      font-family: 'Noble';
      color: #000000; }
      #compare-vehicles .input-group-container label span {
        position: relative;
        top: 5px;
        margin-left: 5px; }
    #compare-vehicles .input-group-container .input-group select {
      cursor: pointer;
      color: #000000;
      font-family: 'Noble';
      position: relative;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #D8D8D8;
      background: #fff;
      padding: 16px 45px 16px 15px;
      font-size: 16px;
      -webkit-appearance: none;
              appearance: none;
      background-repeat: no-repeat;
      background-position: 95% 12px;
      font-size: 14px;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 11.0117L16 20.0117L7 11.0117' stroke='black' stroke-width='3'/%3E%3C/svg%3E"); }
    #compare-vehicles .input-group-container .input-group select::-ms-expand {
      display: none; }
  #compare-vehicles .single-vehicle {
    padding-top: 50px;
    padding-bottom: 50px; }
    #compare-vehicles .single-vehicle h3 {
      font-size: 20px; }
    #compare-vehicles .single-vehicle h4 {
      font-size: 16px; }
  #compare-vehicles .BreakevenChart-section-container {
    display: -ms-flexbox;
    display: flex;
    padding: 2rem 25rem;
    -ms-flex-pack: end;
        justify-content: flex-end; }
    #compare-vehicles .BreakevenChart-section-container .BreakevenChart-container {
      width: 825px; }

/*
Naming Schema :
Component Outer Class
inner class Kebab Case
all lowercase with - separating words.
//$CalcPanels-nav-button-background-color_active:  #000000;

Include  this any componets scss file
@import "src/client_customizations/scss/client_master.scss";

*/
/* Fonts */
/*
    Header
*/
/*
    Main
*/
/*
Page Layout
*/
/*
    Generic Footer
*/
/*
    CalcPanels
*/
/*
    EVCard
*/
/*
    PricePanels
*/
/*
    Incentive Card
*/
/*
    Incentive Preferences
*/
/*
    Link Card
*/
/*
    Charging Station
*/
/*
    Dealer Location Card
*/
/*
    ChargingMap
*/
/*
    Render Item
*/
/*
    Button Group
*/
/*
    Input Range
*/
/*
    Event Card
*/
/*
    Event Filter Controls
*/
/*
    Pages: Homepage
*/
/*
    Pages: EVs
*/
/* Pages Compare Vehicles */
/* Pages Compare Vehicles */
/* Pages: Inventory */
/*
    Pages: Events
*/
/* Maps */
.google-map-cluster-marker {
  width: 40px;
  height: 52px;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 80.555 100' enable-background='new 0 0 80.555 100' xml:space='preserve'><path d='M76.389,26.389V18.75c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-8.333V18.75  c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-4.167v4.167c0,5.947,4.157,10.915,9.723,12.18v15.598  l4.156,30.662c-0.059,3.014-2.518,5.449-5.545,5.449c-3.062,0-5.553-2.489-5.556-5.55h0.001V50.005L58.333,50  c0-4.595-3.738-8.333-8.333-8.333h-8.334V8.102C41.667,3.634,37.929,0,33.333,0h-25C3.738,0,0,3.634,0,8.102V100h41.667V47.222H50  c1.531,0,2.777,1.247,2.777,2.778l0.001,0.005v38.883h-0.001c0,6.127,4.984,11.111,11.111,11.111C70.015,100,75,95.016,75,88.889  l-4.167-30.744V42.735c5.565-1.265,9.722-6.232,9.722-12.18v-4.167H76.389z M10.524,73.299l5.983-11.965H9.028l10.47-17.95h7.478  L19.498,55.35h8.974L10.524,73.299z M36.111,29.398c0,1.404-1.247,2.546-2.778,2.546h-25c-1.531,0-2.778-1.142-2.778-2.546V8.102  c0-1.404,1.247-2.546,2.778-2.546h25c1.531,0,2.778,1.142,2.778,2.546V29.398z'/></svg>");
  border-radius: 10%;
  padding: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
  -ms-flex-align: top;
      align-items: top; }
  .google-map-cluster-marker .text {
    background-color: #fff;
    height: 12px; }

.google-map-location-marker-button {
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627; }
  .google-map-location-marker-button :hover {
    background-color: transparent; }
  .google-map-location-marker-button :active {
    position: relative;
    top: 1px; }
  .google-map-location-marker-button :hover {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    z-index: 9999; }
  .google-map-location-marker-button :focus {
    outline: none; }

.ComparedCarDetails {
  font-family: 'Noble';
  margin: 0;
  text-align: center; }
  .ComparedCarDetails .btn {
    margin-top: 1.5rem; }
  .ComparedCarDetails .title {
    color: #767676;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    text-transform: uppercase; }
  .ComparedCarDetails .value {
    color: #000000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 10px 0 0; }
  .ComparedCarDetails .afterIncentives .value {
    font-size: 32px; }
  .ComparedCarDetails p {
    margin-bottom: 0; }
  .ComparedCarDetails .afterIncentives .DetailColumn {
    border-top: 1px solid #dbdbdb; }
  .ComparedCarDetails .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    .ComparedCarDetails .row.last .DetailColumn {
      border-bottom: none;
      margin-bottom: 50px; }
    .ComparedCarDetails .row .DetailColumn {
      width: 100%;
      top: 0.4rem;
      padding: 0.5rem 0 1.12rem 0;
      border-bottom: 1px solid #dbdbdb;
      text-align: center; }
  .ComparedCarDetails .h5 {
    font-size: 24px;
    font-weight: 600;
    color: #505357; }

/*
Naming Schema :
Component Outer Class
inner class Kebab Case
all lowercase with - separating words.
//$CalcPanels-nav-button-background-color_active:  #000000;

Include  this any componets scss file
@import "src/client_customizations/scss/client_master.scss";

*/
/* Fonts */
/*
    Header
*/
/*
    Main
*/
/*
Page Layout
*/
/*
    Generic Footer
*/
/*
    CalcPanels
*/
/*
    EVCard
*/
/*
    PricePanels
*/
/*
    Incentive Card
*/
/*
    Incentive Preferences
*/
/*
    Link Card
*/
/*
    Charging Station
*/
/*
    Dealer Location Card
*/
/*
    ChargingMap
*/
/*
    Render Item
*/
/*
    Button Group
*/
/*
    Input Range
*/
/*
    Event Card
*/
/*
    Event Filter Controls
*/
/*
    Pages: Homepage
*/
/*
    Pages: EVs
*/
/* Pages Compare Vehicles */
/* Pages Compare Vehicles */
/* Pages: Inventory */
/*
    Pages: Events
*/
/* Maps */
.google-map-cluster-marker {
  width: 40px;
  height: 52px;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 80.555 100' enable-background='new 0 0 80.555 100' xml:space='preserve'><path d='M76.389,26.389V18.75c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-8.333V18.75  c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-4.167v4.167c0,5.947,4.157,10.915,9.723,12.18v15.598  l4.156,30.662c-0.059,3.014-2.518,5.449-5.545,5.449c-3.062,0-5.553-2.489-5.556-5.55h0.001V50.005L58.333,50  c0-4.595-3.738-8.333-8.333-8.333h-8.334V8.102C41.667,3.634,37.929,0,33.333,0h-25C3.738,0,0,3.634,0,8.102V100h41.667V47.222H50  c1.531,0,2.777,1.247,2.777,2.778l0.001,0.005v38.883h-0.001c0,6.127,4.984,11.111,11.111,11.111C70.015,100,75,95.016,75,88.889  l-4.167-30.744V42.735c5.565-1.265,9.722-6.232,9.722-12.18v-4.167H76.389z M10.524,73.299l5.983-11.965H9.028l10.47-17.95h7.478  L19.498,55.35h8.974L10.524,73.299z M36.111,29.398c0,1.404-1.247,2.546-2.778,2.546h-25c-1.531,0-2.778-1.142-2.778-2.546V8.102  c0-1.404,1.247-2.546,2.778-2.546h25c1.531,0,2.778,1.142,2.778,2.546V29.398z'/></svg>");
  border-radius: 10%;
  padding: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: left;
      justify-content: left;
  -ms-flex-align: top;
      align-items: top; }
  .google-map-cluster-marker .text {
    background-color: #fff;
    height: 12px; }

.google-map-location-marker-button {
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627; }
  .google-map-location-marker-button :hover {
    background-color: transparent; }
  .google-map-location-marker-button :active {
    position: relative;
    top: 1px; }
  .google-map-location-marker-button :hover {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    z-index: 9999; }
  .google-map-location-marker-button :focus {
    outline: none; }

.ComparedVehicleToggle {
  padding-bottom: 20px; }
  .ComparedVehicleToggle .btn-group {
    width: 100%; }
  .ComparedVehicleToggle .btn {
    -ms-flex: 1 1;
        flex: 1 1;
    line-height: 1em;
    background-color: #948CF2;
    color: #ffffff;
    padding: 1rem 0.75rem; }
  .ComparedVehicleToggle .btn.active {
    background-color: #403382;
    color: #ffffff; }

.emissions-container {
  width: 70%; }
  @media (max-width: 768px) {
    .emissions-container {
      width: 100%; } }
  .emissions-container h2 {
    font-family: 'Noble';
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 1.3em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px; }
    .emissions-container h2 strong {
      font-family: 'Noble-Bold'; }
  .emissions-container h3 {
    font-family: 'Noble-Book';
    text-align: center;
    font-size: 14px;
    font-weight: 400; }
    .emissions-container h3 strong {
      font-family: 'Noble-Bold'; }
  .emissions-container .emissions-bar-graph-container {
    width: 60%;
    min-width: 550px;
    margin: 50px auto 0; }
    @media (max-width: 768px) {
      .emissions-container .emissions-bar-graph-container {
        width: 100%;
        min-width: 0; } }
    .emissions-container .emissions-bar-graph-container .emissions-bar-graph {
      height: 400px; }
      .emissions-container .emissions-bar-graph-container .emissions-bar-graph canvas {
        width: 100% !important; }
      .emissions-container .emissions-bar-graph-container .emissions-bar-graph p {
        font-family: 'Noble-Book';
        text-align: center;
        font-size: 14px;
        font-weight: 400; }
        .emissions-container .emissions-bar-graph-container .emissions-bar-graph p a {
          font-family: "Noble-Bold";
          color: #000000;
          text-transform: uppercase;
          text-decoration: underline;
          -webkit-text-decoration-color: #9C8654;
                  text-decoration-color: #9C8654;
          font-weight: 700; }

@font-face {
  font-family: 'Noble-Light';
  font-style: normal;
  font-weight: 200;
  src: local("Noble Light"), url(/static/media/fonnts.com-Nobel_Light.ec688d2f.otf) format("opentype"); }

@font-face {
  font-family: 'Noble-Book';
  font-style: normal;
  font-weight: 400;
  src: local("Noble Book"), url(/static/media/fonnts.com-Nobel_Book.9c44bb41.otf) format("opentype"); }

@font-face {
  font-family: 'Noble';
  font-style: normal;
  font-weight: 500;
  src: local("Noble"), url(/static/media/fonnts.com-Nobel_Regular.9785784f.otf) format("opentype"); }

@font-face {
  font-family: 'Noble-Bold';
  font-style: normal;
  font-weight: 700;
  src: local("Noble Bold"), url(/static/media/fonnts.com-Nobel_Bold.41dae115.otf) format("opentype"); }

body, html {
  margin: 0;
  padding: 0; }

.mobile-only {
  display: none; }
  @media (max-width: 768px) {
    .mobile-only {
      display: block; } }

@media (max-width: 768px) {
  .desktop-only {
    display: none; } }

.main-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
  width: 100vw;
  height: auto;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.tooltipMain > .tooltip-inner {
  font-family: 'Noble';
  padding: 10px !important;
  text-align: center !important;
  color: #fff;
  font-size: 13px;
  font-weight: 500; }

.tooltipBody {
  border-radius: 5px;
  background-color: #1E2123 !important; }

.tooltipArrow::before {
  border-right-color: rgba(97, 97, 97, 0.9) !important; }

.title-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 50px 0 0; }
  .title-container h1 {
    font-family: 'Noble-Book';
    margin: 0 0 40px;
    font-size: 24px;
    font-weight: 390;
    text-align: center; }
    @media (max-width: 768px) {
      .title-container h1 {
        padding: 0 15px;
        font-size: 26px; } }
  .title-container p {
    font-size: 48px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.5px;
    text-align: center; }

.tabs-container {
  width: 100%;
  margin: 0 0 60px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }
  .tabs-container .tabs button {
    font-family: 'Noble';
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    padding: 8px 24px;
    font-size: 13px; }
    .tabs-container .tabs button:focus {
      outline: none; }
    @media (max-width: 560px) {
      .tabs-container .tabs button {
        padding: 8px 15px;
        font-size: 14px; } }

.container {
  -ms-flex-align: start;
      align-items: flex-start; }
  @media (max-width: 768px) {
    .container {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }

.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  box-sizing: border-box; }

.container-fluid {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.content-container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center; }

.filters-container {
  width: 350px;
  margin-right: 50px; }
  @media (max-width: 768px) {
    .filters-container {
      width: 100%;
      margin-right: 0;
      margin-bottom: 50px; } }
  .filters-container h2 {
    font-family: 'Noble-Bold';
    margin: 0 0 20px;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    line-height: 1em; }
  .filters-container hr {
    border: 1px solid #D8D8D8;
    margin: 30px 0 25px; }
  .filters-container .input-group-container {
    margin-bottom: 25px; }
    .filters-container .input-group-container .input-group-container label {
      display: none !important; }
  .filters-container .incentive-label-select, .filters-container .input-group-container label, .filters-container .incentive-label-select label {
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 10px;
    font-family: 'Noble';
    color: #000000;
    height: 40px; }
    .filters-container .incentive-label-select:empty, .filters-container .input-group-container label:empty, .filters-container .incentive-label-select label:empty {
      display: none; }
    .filters-container .incentive-label-select span, .filters-container .input-group-container label span, .filters-container .incentive-label-select label span {
      position: relative;
      margin-left: 5px;
      height: 20px; }
  .filters-container .zip-code-input-group {
    position: relative; }
    .filters-container .zip-code-input-group .input-group-prepend {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px; }
      .filters-container .zip-code-input-group .input-group-prepend .input-group-text {
        background-color: white;
        border: none; }
    .filters-container .zip-code-input-group input.form-control {
      font-family: 'Noble';
      width: 100%;
      padding: 16px 45px 16px 50px;
      border: 1px solid #000000;
      background: #fff;
      font-size: 16px;
      box-sizing: border-box;
      height: 48px !important;
      border-radius: 0px;
      background-repeat: no-repeat;
      background-position: 92% 10px;
      background-image: url(/static/media/zipdode_bar.278370ed.svg); }
  .filters-container .Range.form-group {
    margin: -10px 0 0; }
    .filters-container .Range.form-group input[type="range"] {
      position: relative;
      width: 100%;
      height: 25px;
      outline: none;
      background: transparent;
      border: none; }
      .filters-container .Range.form-group input[type="range"]:before {
        position: relative;
        top: 7px;
        content: "";
        display: block;
        width: 3px;
        height: 12px;
        background-color: #D8D8D8; }
      .filters-container .Range.form-group input[type="range"]:after {
        position: relative;
        top: 7px;
        content: "";
        display: block;
        width: 3px;
        height: 12px;
        background-color: #D8D8D8; }
  .filters-container .form-group.currency-wrap {
    position: relative; }
    .filters-container .form-group.currency-wrap input[type="number"] {
      position: relative;
      font-family: 'Noble';
      position: relative;
      width: 100%;
      height: 25px;
      font-size: 14px;
      border-radius: 8px;
      border: 1px solid #D8D8D8;
      padding: 25px 45px 25px 20px;
      box-sizing: border-box;
      font-size: 14px;
      z-index: 4; }
    .filters-container .form-group.currency-wrap:before {
      position: absolute;
      content: "$";
      top: 14px;
      left: 10px;
      font-family: 'Noble';
      color: #000000;
      z-index: 5; }
    .filters-container .form-group.currency-wrap:after {
      position: absolute;
      content: " / kWh";
      top: 14px;
      left: 50px;
      font-family: 'Noble';
      color: #000000;
      z-index: 5; }
    .filters-container .form-group.currency-wrap .currency-disclaimer p {
      font-family: 'Noble-Light';
      font-size: 12px; }
  .filters-container .text-danger.small {
    font-family: 'Noble';
    font-size: 12px;
    line-height: 1em; }
  .filters-container select {
    color: #000000;
    cursor: pointer;
    font-family: 'Noble';
    position: relative;
    width: 100%;
    border: 1px solid #000000;
    border-radius: 0px;
    background: #fff;
    font-size: 16px;
    -webkit-appearance: none;
            appearance: none;
    height: 48px !important;
    background-repeat: no-repeat;
    background-position: 98% 0px;
    background-image: url(/static/media/arrow_bordered.786971a5.svg); }
  .filters-container select::-ms-expand {
    display: none; }
  .filters-container .incentives-submit-button {
    font-family: 'Noble';
    font-size: 16px;
    font-weight: 600;
    line-height: 1em;
    width: 100%;
    padding: 15px 0 17px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    cursor: pointer; }
  .filters-container .incentives-disclaimer {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Noble-Light';
    margin-top: 25px;
    line-height: 1.2em;
    color: #000000; }

.disclaimer {
  margin-top: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left; }

.footer-container {
  width: 100%;
  margin: 100px 0 0;
  padding: 0 0 50px; }
  .footer-container .footer-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .footer-container .footer-content img {
      max-width: 100%; }
    .footer-container .footer-content p {
      font-family: 'Noble-Light';
      font-size: 12px;
      text-align: center; }

.btn-lexus {
  background-color: #ffffff;
  color: #000000;
  font-weight: 700;
  font-size: 13px;
  font-family: 'Noble-Bold';
  width: 100%;
  border: 2px solid #000000;
  height: 48px;
  cursor: pointer; }
  .btn-lexus:focus {
    outline: none; }
  .btn-lexus:hover {
    color: #ffffff;
    background-color: #000000; }

.input-group-container {
  margin: 20px 0px; }
  .input-group-container .form-control {
    color: #000000;
    cursor: pointer;
    font-family: 'Noble';
    position: relative;
    width: 100%;
    border: 1px solid #000000;
    border-radius: 0px;
    background: #fff;
    font-size: 16px;
    -webkit-appearance: none;
            appearance: none;
    height: 48px !important;
    background-repeat: no-repeat;
    background-position: 98% 0px;
    background-image: url(/static/media/arrow_bordered.786971a5.svg); }

#zipcodefield.form-control {
  font-family: 'Noble';
  width: 100%;
  border: 1px solid #000000;
  background: #fff;
  font-size: 16px;
  box-sizing: border-box;
  height: 48px !important;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-position: 92% 10px;
  background-image: url(/static/media/zipdode_bar.278370ed.svg); }

.modal-body .incentives-disclaimer {
  font-size: 11px;
  font-weight: 400;
  font-family: 'Noble-Light';
  margin-top: 25px;
  line-height: 1.2em;
  color: #000000; }

.modal-body h2 {
  font-family: 'Noble';
  font-size: 16px;
  font-weight: 700; }

.modal-body label {
  font-size: 14px; }

.modal-title .btn {
  background-color: #ffffff;
  color: #000000;
  border: none;
  display: -ms-flexbox;
  display: flex;
  height: 23px;
  -ms-flex-pack: end;
      justify-content: flex-end; }

.modal-content .modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  border: none; }

.modal-footer {
  border-top: none; }
  .modal-footer .btn {
    font-family: 'Noble';
    border-radius: 0px;
    font-weight: 700;
    font-size: 13px; }

