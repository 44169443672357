@import 'src/client_customizations/scss/client_master.scss';

#compare-vehicles {
       flex-direction: column;
       width:100%;

       .container {
              flex-direction: column;
       }

       .compare-vehicles-toggle {
              width: 100%;
              display:none;

              @media(max-width:768px) {
                     display:block;
              }

              .ComparedVehicleToggle {
                     button.btn {
                            font-family: 'Noble';
                            width:50%;
                            background-color: #B00716;
                            border:none;
                            font-size:20px;
                            cursor: pointer;

                            &.active {
                                   font-weight: bold;
                                   background-color: #9C8654;
                            }
                     }
              }
       }

       .compare-vehicles-title {
              width:100%;

              h2 {
                     font-family: 'Noble-Light';
                     text-transform: none;
                     text-align: center;
                     margin:0;
                     font-size: 24px;
              }

              p.lead {
                     font-family: 'Noble-Light';
                     text-align: center;
                     font-size:16px;
              }
       }

       .compared-section {
              width:100%;
              display: flex;
              justify-content: center;
              gap:50px;
       }

       .compared-vehicle {
              width: 50%;
              max-width:300px;

              @media(max-width:768px) {
                     width:100%;
                     max-width: none;

                     &.d-none {
                            display:none;
                     }
              }

              .ComparedCarDetails {
                     img {
                            width:100%;
                     }
              }
       }

       .compared-graph-section {
              width: 100%;
              display: flex;
              background: #F6F6F6;
              padding:75px 0 0;

              .container {
                     width:100%;
                     display: flex;
                     flex-direction: row;
                     align-items: flex-start
              }

              .graphs-container {
                     width: 70%;
                     margin:0 auto;

                     @media(max-width:768px) {
                            width:100%;
                     }

                     .chart-container {
                            background-color:#ffffff;
                            border:1px solid #D8D8D8;
                            padding:40px;
                            text-align: center;
                            border-radius: 8px;
                            margin:0 0 50px;

                            &.nmb {
                                   margin-bottom:0;
                            }

                            .graph-sub-title {
                                   font-family: 'Noble';
                                   margin:0 0 50px;
                                   font-size:18px;
                            }

                            .bubble-container {
                                   .bubble {
                                          justify-content: center;

                                          &.large {
                                                 img {
                                                        height:50px;
                                                 }
                                          }

                                          &.medium {
                                                 img {
                                                        height:30px;
                                                 }
                                          }

                                          &.small {
                                                 img {
                                                        width:20px;
                                                        height:auto;
                                                 }
                                          }

                                          p {
                                                 font-family: 'Noble';
                                                 margin:0;
                                          }
                                   }
                            }

                            .bubble-title {
                                   font-family: 'Noble-Light';
                                   margin:20px 0 0;
                                   font-size:16px;
                            }
                     }

                     .chart-disclaimer {
                            font-family: 'Noble-Light';
                            font-size: 12px;
                            color: #000000;
                            margin:0 0 50px;
                     }
              }
       }

       .input-group-container {
              margin-bottom: 25px;

              label {
                     display: flex;
                     margin:0 0 10px;
                     font-family: 'Noble';
                     color:#000000;

                     span {
                            position:relative;
                            top:5px;
                            margin-left:5px;
                     }
              }

              .input-group {
                     select {
                            cursor: pointer;
                            color:#000000;
                            font-family: 'Noble';
                            position: relative;
                            width:100%;
                            border-radius: 8px;
                            border: 1px solid #D8D8D8;
                            background: #fff;
                            padding: 16px 45px 16px 15px;
                            font-size: 16px;
                            appearance: none;
                            background-repeat: no-repeat;
                            background-position: 95% 12px;
                            font-size:14px;
                            background-image: url("data:image/svg+xml,%3Csvg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 11.0117L16 20.0117L7 11.0117' stroke='black' stroke-width='3'/%3E%3C/svg%3E");
                     }

                     select::-ms-expand {
                            display: none;
                     }
              }
       }

       .single-vehicle {
              padding-top: 50px;
              padding-bottom: 50px;

              h3 {
                     font-size:20px;
              }

              h4 {
                     font-size:16px;
              }
       }

       .BreakevenChart-section-container {
              display: flex;
              padding: 2rem 25rem;
              justify-content: flex-end;

              .BreakevenChart-container {
                     width: 825px;
              }
       }
}
