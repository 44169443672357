.doughnutGraph-container {
       width:100%;
       display:flex;

       @media(max-width:991px) {
              flex-direction: column;
       }

       .doughnutGraph {
              display: block;
              width:500px;
              height:500px;

              @media(max-width:991px) {
                     width: 100%;
                     height:auto;
              }

              canvas {
                     width:100% !important;
              }
       }

       .doughnutGraph-legend-container {
              width:calc(100% - 500px);
              display:flex;
              align-content: center;
              flex-wrap: wrap;

              @media(max-width:991px) {
                     width: 100%;
              }

              .doughnutGraph-legend {
                     width:100%;
                     margin:0 0 5px;
                     display: flex;
                     align-items: center;

                     span.indication-block {
                            position: relative;
                            top:2px;
                            width:16px;
                            height:16px;
                            display: block;
                            margin:0 10px 0 0;
                     }

                     span {
                            font-family: 'Noble-Light';
                            font-size: 16px;
                            color: #000000;
                            font-weight: 600;
                     }
              }
       }
}